import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_SUCCESS,
  LOGIN_WITH_GOOGLE_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  MANUALLY_VERIFY_EMAIL,
  MANUALLY_VERIFY_EMAIL_SUCCESS,
  MANUALLY_VERIFY_EMAIL_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_TOKEN_VERIFY,
  FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS,
  FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_EMPLOYER_LIST,
  GET_EMPLOYER_LIST_SUCCESS,
  GET_EMPLOYER_LIST_FAILURE,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SEND_EMAIL_VERIFICATION_FAILURE,
  DELETE_EMPLOYER_PROFILE,
  DELETE_EMPLOYER_PROFILE_SUCCESS,
  DELETE_EMPLOYER_PROFILE_FAILURE,
  DELETE_EMPLOYER,
  DELETE_EMPLOYER_SUCCESS,
  DELETE_EMPLOYER_FAILURE,
  RESTORE_PROFILE,
  RESTORE_PROFILE_SUCCESS,
  RESTORE_PROFILE_FAILURE,
  EMPLOYER_SUBSCRIPTION,
  EMPLOYER_SUBSCRIPTION_SUCCESS,
  EMPLOYER_SUBSCRIPTION_FAILURE,
  GET_CREDIT_HISTORY,
  GET_CREDIT_HISTORY_SUCCESS,
  GET_CREDIT_HISTORY_FAILURE,
  UPDATE_CREDIT_HISTORY,
  UPDATE_CREDIT_HISTORY_SUCCESS,
  UPDATE_CREDIT_HISTORY_FAILURE,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  GET_ALL_EMPLOYERS,
  GET_ALL_EMPLOYERS_SUCCESS,
  GET_ALL_EMPLOYERS_FAILURE,
  ADD_OR_EDIT_SUB_USER,
  ADD_OR_EDIT_SUB_USER_SUCCESS,
  ADD_OR_EDIT_SUB_USER_FAILURE,
  GET_SUB_USER,
  GET_SUB_USER_SUCCESS,
  GET_SUB_USER_FAILURE,
  DELETE_SUB_USER,
  DELETE_SUB_USER_SUCCESS,
  DELETE_SUB_USER_FAILURE,
  RESEND_LINK,
  RESEND_LINK_SUCCESS,
  RESEND_LINK_FAILURE,
  ACTIVE_INACTIVE_SUBUSER,
  ACTIVE_INACTIVE_SUBUSER_SUCCESS,
  ACTIVE_INACTIVE_SUBUSER_FAILURE,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  ADD_OR_EDIT_ROLE,
  ADD_OR_EDIT_ROLE_SUCCESS,
  ADD_OR_EDIT_ROLE_FAILURE,
  DELETE_ROLES,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAILURE,
  ACTIVE_INACTIVE_ROLE,
  ACTIVE_INACTIVE_ROLE_SUCCESS,
  ACTIVE_INACTIVE_ROLE_FAILURE,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  ADD_ROLES,
  ADD_ROLES_SUCCESS,
  ADD_ROLES_FAILURE,
  GET_ROLES_RESOURCES,
  GET_ROLES_RESOURCES_SUCCESS,
  GET_ROLES_RESOURCE_FAILURE,
  GET_ALL_SUB_USER,
  GET_ALL_SUB_USER_SUCCESS,
  GET_ALL_SUB_USER_FAILURE,
  ADD_OR_EDIT_EMPLOYER_SUB_USER,
  ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS,
  ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE,
  GET_RESOURCE_ACTIONS,
  GET_RESOURCE_ACTIONS_SUCCESS,
  GET_RESOURCE_ACTIONS_FAILURE,
  GET_CONTACT_INQUIRY,
  GET_CONTACT_INQUIRY_SUCCESS,
  GET_CONTACT_INQUIRY_FAILURE,
  GET_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_LIST_FAILURE,
  GET_ANNOUNCEMENT_LIST_SUCCESS,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_FAILURE,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_INFORMATION_FAILURE,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
  UPDATE_COMPANY_DETAILS,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_FAILURE,
  GET_SUPPORT_TICKET,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAILURE,
  UPDATE_SUPPORT_TICKET_STATUS,
  UPDATE_SUPPORT_TICKET_STATUS_SUCCESS,
  UPDATE_SUPPORT_TICKET_STATUS_FAILURE,
  DELETE_EMPLOYER_SUB_USER,
  DELETE_EMPLOYER_SUB_USER_SUCCESS,
  DELETE_EMPLOYER_SUB_USER_FAILURE,
  SEND_ANNOUNCEMENT_ITEM,
  SEND_ANNOUNCEMENT_ITEM_SUCCESS,
  SEND_ANNOUNCEMENT_ITEM_FAILURE,
  GET_REFER_AND_EARN_LIST,
  GET_REFER_AND_EARN_LIST_SUCCESS,
  GET_REFER_AND_EARN_LIST_FAILURE,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  EDIT_STATUS_CONTACT_INQUIRY,
  EDIT_STATUS_CONTACT_INQUIRY_SUCCESS,
  EDIT_STATUS_CONTACT_INQUIRY_FAILURE,
  EDIT_USER_CONTACT_INQUIRY,
  EDIT_USER_CONTACT_INQUIRY_SUCCESS,
  EDIT_USER_CONTACT_INQUIRY_FAILURE,
  ADD_SETTLE_REFER,
  ADD_SETTLE_REFER_SUCCESS,
  ADD_SETTLE_REFER_FAILURE,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE,
  SEND_SUBUSER_VERIFICATION_MAIL,
  SEND_SUBUSER_VERIFICATION_MAIL_SUCCESS,
  SEND_SUBUSER_VERIFICATION_MAIL_FAILURE,
  GET_EMPLOYER_PERMISSION,
  GET_EMPLOYER_PERMISSION_SUCCESS,
  GET_EMPLOYER_PERMISSION_FAILURE,
  GET_EMPLOYER_ROLE_LIST,
  GET_EMPLOYER_ROLE_LIST_SUCCESS,
  GET_EMPLOYER_ROLE_LIST_FAILURE,
  GET_FAQ_LIST_SUCCESS,
  GET_FAQ_LIST_FAILURE,
  GET_TAG_LIST,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_FAILURE,
  ADD_OR_EDIT_FAQ,
  ADD_OR_EDIT_FAQ_SUCCESS,
  ADD_OR_EDIT_FAQ_FAILURE,
  DELETE_FAQ_LIST,
  DELETE_FAQ_LIST_SUCCESS,
  DELETE_FAQ_LIST_FAILURE,
  GET_ACTIVE_EMPLOYER,
  GET_ACTIVE_EMPLOYER_SUCCESS,
  GET_ACTIVE_EMPLOYER_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_WHITE_LABEL_DETAILS,
  GET_WHITE_LABEL_DETAILS_FAILURE,
  GET_WHITE_LABEL_DETAILS_SUCCESS,
  GET_PRODUCT_PERMISSION,
  GET_PRODUCT_PERMISSION_SUCCESS,
  GET_PRODUCT_PERMISSION_FAILURE,
  GET_PRODUCT_PERMISSION_CREDIT,
  GET_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  GET_PRODUCT_PERMISSION_CREDIT_FAILURE,
  UPDATE_PRODUCT_PERMISSION_CREDIT,
  UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE,
  UPDATE_PRODUCT_PERMISSION,
  UPDATE_PRODUCT_PERMISSION_SUCCESS,
  UPDATE_PRODUCT_PERMISSION_FAILURE,
  NOTIFY_CLIENT,
  NOTIFY_CLIENT_SUCCESS,
  NOTIFY_CLIENT_FAILURE,
  GET_EMPLOYER_SUBUSER_DETAILS,
  GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS,
  GET_EMPLOYER_SUBUSER_DETAILS_FAILURE,
  ADD_PRODUCT_PERMISSION,
  ADD_PRODUCT_PERMISSION_SUCCESS,
  ADD_PRODUCT_PERMISSION_FAILURE,
  NOTIFY_INCSERVE_CLIENT,
  NOTIFY_INCSERVE_CLIENT_SUCCESS,
  NOTIFY_INCSERVE_CLIENT_FAILURE,
  DELETE_FAQS_LIST,
  DELETE_FAQS_LIST_SUCCESS,
  DELETE_FAQS_LIST_FAILURE,
  EDIT_USER_SUPPORT,
  EDIT_USER_SUPPORT_SUCCESS,
  EDIT_USER_SUPPORT_FAILURE,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILURE,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  READ_ALL_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  CHANGE_EMPLOYER_STATUS,
  CHANGE_EMPLOYER_STATUS_SUCCESS,
  CHANGE_EMPLOYER_STATUS_FAILURE,
  RESTORE_SUBUSER,
  RESTORE_SUBUSER_SUCCESS,
  RESTORE_SUBUSER_FAILURE,
  SUB_ADMIN_PASSWORD_RESET,
  SUB_ADMIN_PASSWORD_RESET_SUCCESS,
  SUB_ADMIN_PASSWORD_RESET_FAILURE,
  GET_FAQ_LIST,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE,
  GET_2_FACTOR_AUTHENTICATION_URI,
  GET_2_FACTOR_AUTHENTICATION_URI_SUCCESS,
  GET_2_FACTOR_AUTHENTICATION_URI_FAILURE,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  TWO_FACTOR_AUTHENTICATION_STATUS,
  TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
  GET_MOCK_INTERVIEWS,
  GET_MOCK_INTERVIEWS_SUCCESS,
  GET_MOCK_INTERVIEWS_FAILURE,
} from "./types";

export const getEmployerRoleList = (payload) => ({
  type: GET_EMPLOYER_ROLE_LIST,
  payload,
});
export const getEmployerRoleListSuccess = (payload) => ({
  type: GET_EMPLOYER_ROLE_LIST_SUCCESS,
  payload,
});
export const getEmployerRoleListFailure = () => ({
  type: GET_EMPLOYER_ROLE_LIST_FAILURE,
});

export const getWhiteLabelDetails = (payload) => ({
  type: GET_WHITE_LABEL_DETAILS,
  payload,
});
export const getWhiteLabelDetailsSuccess = (payload) => ({
  type: GET_WHITE_LABEL_DETAILS_SUCCESS,
  payload,
});
export const getWhiteLabelDetailsFailure = () => ({
  type: GET_WHITE_LABEL_DETAILS_FAILURE,
});

export const addReferSettleReward = (payload) => ({
  type: ADD_SETTLE_REFER,
  payload,
});
export const addReferSettleRewardSuccess = () => ({
  type: ADD_SETTLE_REFER_SUCCESS,
});
export const addReferSettleRewardFailure = () => ({
  type: ADD_SETTLE_REFER_FAILURE,
});

export const getUserList = () => ({
  type: GET_USER_LIST,
});
export const getUserListSuccess = (payload) => ({
  type: GET_USER_LIST_SUCCESS,
  payload,
});
export const getUserListFailure = () => ({
  type: GET_USER_LIST_FAILURE,
});
export const editStatusContactInquiry = (payload) => ({
  type: EDIT_STATUS_CONTACT_INQUIRY,
  payload,
});
export const editStatusContactInquirySuccess = () => ({
  type: EDIT_STATUS_CONTACT_INQUIRY_SUCCESS,
});
export const editStatusContactInquiryFailure = () => ({
  type: EDIT_STATUS_CONTACT_INQUIRY_FAILURE,
});
export const editUserContactInquiry = (payload) => ({
  type: EDIT_USER_CONTACT_INQUIRY,
  payload,
});
export const editUserContactInquirySuccess = () => ({
  type: EDIT_USER_CONTACT_INQUIRY_SUCCESS,
});
export const editUserContactInquiryFailure = () => ({
  type: EDIT_USER_CONTACT_INQUIRY_FAILURE,
});
export const editUserSupport = (payload) => ({
  type: EDIT_USER_SUPPORT,
  payload,
});
export const editUserSupportSuccess = () => ({
  type: EDIT_USER_SUPPORT_SUCCESS,
});
export const editUserSupportFailure = () => ({
  type: EDIT_USER_SUPPORT_FAILURE,
});
// REFER AND EARN
export const getReferAndEarn = (payload) => ({
  type: GET_REFER_AND_EARN_LIST,
  payload,
});
export const getReferAndEarnSuccess = (payload) => ({
  type: GET_REFER_AND_EARN_LIST_SUCCESS,
  payload,
});
export const getReferAndEarnFailure = (payload) => ({
  type: GET_REFER_AND_EARN_LIST_FAILURE,
  payload,
});

export const getReferUsers = (payload) => ({
  type: GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST,
  payload,
});
export const getReferAndEarnFilterSuccess = (payload) => ({
  type: GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS,
  payload,
});
export const getReferAndEarnFilterFailure = (payload) => ({
  type: GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE,
  payload,
});

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  payload,
});
export const loginUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});
export const loginUserFailure = () => ({
  type: LOGIN_USER_FAILURE,
});

export const loginWithGoogle = (payload) => ({
  type: LOGIN_WITH_GOOGLE,
  payload,
});
export const loginWithGoogleSuccess = (payload) => ({
  type: LOGIN_WITH_GOOGLE_SUCCESS,
  payload,
});
export const loginWithGoogleFailure = () => ({
  type: LOGIN_WITH_GOOGLE_FAILURE,
});

export const logoutUser = (payload) => ({
  type: LOGOUT_USER,
  payload,
});
export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});
export const logoutUserFailure = () => ({
  type: LOGOUT_USER_FAILURE,
});

export const getEmployerAccountLogs = (payload) => ({
  type: INCSERVE_EMPLOYER_ACCOUNT_LOGS,
  payload,
});
export const getEmployerAccountLogsSuccess = (payload) => ({
  type: INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS,
  payload,
});
export const getEmployerAccountLogsFailure = () => ({
  type: INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE,
});

export const signupUser = (payload) => ({
  type: SIGNUP_USER,
  payload,
});
export const signupUserSuccess = () => ({
  type: SIGNUP_USER_SUCCESS,
});
export const signupUserFailure = () => ({
  type: SIGNUP_USER_FAILURE,
});
export const manuallyVerifyEmail = (payload) => ({
  type: MANUALLY_VERIFY_EMAIL,
  payload,
});
export const manuallyVerifyEmailSuccess = () => ({
  type: MANUALLY_VERIFY_EMAIL_SUCCESS,
});
export const manuallyVerifyEmailFailure = () => ({
  type: MANUALLY_VERIFY_EMAIL_FAILURE,
});
// FORGET PASSWORD
export const forgotPassword = (payload) => ({
  type: FORGOT_PASSWORD,
  payload,
});
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});
export const forgotPasswordFailure = () => ({
  type: FORGOT_PASSWORD_FAILURE,
});
export const forgotPasswordTokenVerify = (payload) => ({
  type: FORGOT_PASSWORD_TOKEN_VERIFY,
  payload,
});
export const forgotPasswordTokenVerifySuccess = () => ({
  type: FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS,
});
export const forgotPasswordTokenVerifyFailure = () => ({
  type: FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE,
});
export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});
export const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE,
});

// EMPLOYER LIST
export const getEmployerList = (payload) => ({
  type: GET_EMPLOYER_LIST,
  payload,
});
export const getEmployerListSuccess = (payload) => ({
  type: GET_EMPLOYER_LIST_SUCCESS,
  payload,
});
export const getEmployerListFailure = () => ({
  type: GET_EMPLOYER_LIST_FAILURE,
});

export const getAllEmployers = (payload) => ({
  type: GET_ALL_EMPLOYERS,
  payload,
});
export const getAllEmployersSuccess = (payload) => ({
  type: GET_ALL_EMPLOYERS_SUCCESS,
  payload,
});
export const getAllEmployersFailure = () => ({
  type: GET_ALL_EMPLOYERS_FAILURE,
});

export const sendEmailVerification = (payload) => ({
  type: SEND_EMAIL_VERIFICATION,
  payload,
});
export const sendEmailVerificationSuccess = () => ({
  type: SEND_EMAIL_VERIFICATION_SUCCESS,
});
export const sendEmailVerificationFailure = () => ({
  type: SEND_EMAIL_VERIFICATION_FAILURE,
});
export const deleteEmployerProfile = (payload) => ({
  type: DELETE_EMPLOYER_PROFILE,
  payload,
});
export const deleteEmployerProfileSuccess = () => ({
  type: DELETE_EMPLOYER_PROFILE_SUCCESS,
});
export const deleteEmployerProfileFailure = () => ({
  type: DELETE_EMPLOYER_PROFILE_FAILURE,
});
export const permanentDeleteEmployerProfile = (payload) => ({
  type: DELETE_EMPLOYER,
  payload,
});
export const permanentDeleteEmployerProfileSuccess = () => ({
  type: DELETE_EMPLOYER_SUCCESS,
});
export const permanentDeleteEmployerProfileFailure = () => ({
  type: DELETE_EMPLOYER_FAILURE,
});
export const restoreEmployerProfile = (payload) => ({
  type: RESTORE_PROFILE,
  payload,
});

export const restoreEmployerProfileSuccess = (payload) => ({
  type: RESTORE_PROFILE_SUCCESS,
  payload,
});

export const restoreEmployerProfileFailure = (payload) => ({
  type: RESTORE_PROFILE_FAILURE,
  payload,
});

export const getEmployerSubscriptions = (payload) => ({
  type: EMPLOYER_SUBSCRIPTION,
  payload,
});

export const getEmployerSubscriptionsSuccess = (payload) => ({
  type: EMPLOYER_SUBSCRIPTION_SUCCESS,
  payload,
});

export const getEmployerSubscriptionsFailure = (payload) => ({
  type: EMPLOYER_SUBSCRIPTION_FAILURE,
  payload,
});
export const putSubscription = (payload) => ({
  type: UPDATE_SUBSCRIPTION,
  payload,
});

export const putSubscriptionSuccess = () => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
});

export const putSubscriptionFailure = () => ({
  type: UPDATE_SUBSCRIPTION_FAILURE,
});

export const getCreditHistory = (payload) => ({
  type: GET_CREDIT_HISTORY,
  payload,
});

export const getCreditHistorySuccess = (payload) => ({
  type: GET_CREDIT_HISTORY_SUCCESS,
  payload,
});

export const getCreditHistoryFailure = () => ({
  type: GET_CREDIT_HISTORY_FAILURE,
});

export const updateCreditHistory = (payload) => ({
  type: UPDATE_CREDIT_HISTORY,
  payload,
});

export const updateCreditHistorySuccess = (payload) => ({
  type: UPDATE_CREDIT_HISTORY_SUCCESS,
  payload,
});

export const updateCreditHistoryFailure = (payload) => ({
  type: UPDATE_CREDIT_HISTORY_FAILURE,
  payload,
});

// ROLLES AND SUBUSER

export const addOrEditSubUser = (payload) => ({
  type: ADD_OR_EDIT_SUB_USER,
  payload,
});
export const addOrEditSubUserSuccess = () => ({
  type: ADD_OR_EDIT_SUB_USER_SUCCESS,
});
export const addOrEditSubUserFailure = () => ({
  type: ADD_OR_EDIT_SUB_USER_FAILURE,
});

export const addOrEditEmployerSubUser = (payload) => ({
  type: ADD_OR_EDIT_EMPLOYER_SUB_USER,
  payload,
});
export const addOrEditEmployerSubUserSuccess = () => ({
  type: ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS,
});
export const addOrEditEmployerSubUserFailure = () => ({
  type: ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE,
});
export const getActiveEmployers = (payload) => ({
  type: GET_ACTIVE_EMPLOYER,
  payload,
});
export const getActiveEmployersSuccess = (payload) => ({
  type: GET_ACTIVE_EMPLOYER_SUCCESS,
  payload,
});
export const getActiveEmployersFailure = () => ({
  type: GET_ACTIVE_EMPLOYER_FAILURE,
});
export const getSubUser = (payload) => ({
  type: GET_SUB_USER,
  payload,
});

export const getSubUserSuccess = (payload) => ({
  type: GET_SUB_USER_SUCCESS,
  payload,
});
export const getSubUserFailure = () => ({
  type: GET_SUB_USER_FAILURE,
});

export const restoreSubuser = (payload) => ({
  type: RESTORE_SUBUSER,
  payload,
});
export const restoreSubuserSuccess = (payload) => ({
  type: RESTORE_SUBUSER_SUCCESS,
  payload,
});
export const restoreSubuserFailure = () => ({
  type: RESTORE_SUBUSER_FAILURE,
});

export const deleteSubUser = (payload) => ({
  type: DELETE_SUB_USER,
  payload,
});
export const deleteSubUserSuccess = () => ({
  type: DELETE_SUB_USER_SUCCESS,
});
export const deleteSubUserFailure = () => ({
  type: DELETE_SUB_USER_FAILURE,
});
export const deleteEmploySubUser = (payload) => ({
  type: DELETE_EMPLOYER_SUB_USER,
  payload,
});
export const deleteEmploySubUserSuccess = () => ({
  type: DELETE_EMPLOYER_SUB_USER_SUCCESS,
});
export const deleteEmploySubUserFailure = () => ({
  type: DELETE_EMPLOYER_SUB_USER_FAILURE,
});
export const resendLink = (payload) => ({
  type: RESEND_LINK,
  payload,
});
export const resendLinkSuccess = () => ({
  type: RESEND_LINK_SUCCESS,
});
export const resendLinkFailure = () => ({
  type: RESEND_LINK_FAILURE,
});
export const activeInactiveSubUser = (payload) => ({
  type: ACTIVE_INACTIVE_SUBUSER,
  payload,
});
export const activeInactiveSubUserSuccess = () => ({
  type: ACTIVE_INACTIVE_SUBUSER_SUCCESS,
});
export const activeInactiveSubUserFailure = () => ({
  type: ACTIVE_INACTIVE_SUBUSER_FAILURE,
});
export const getRoles = (payload) => ({
  type: GET_ROLES,
  payload,
});
export const getRolesSuccess = (payload) => ({
  type: GET_ROLES_SUCCESS,
  payload,
});
export const getRolesFailure = () => ({
  type: GET_ROLES_FAILURE,
});
export const addOrEditRole = (payload) => ({
  type: ADD_OR_EDIT_ROLE,
  payload,
});
export const addOrEditRoleSuccess = () => ({
  type: ADD_OR_EDIT_ROLE_SUCCESS,
});
export const addOrEditRoleFailure = () => ({
  type: ADD_OR_EDIT_ROLE_FAILURE,
});
export const deleteRole = (payload) => ({
  type: DELETE_ROLES,
  payload,
});
export const deleteRoleSuccess = () => ({
  type: DELETE_ROLES_SUCCESS,
});
export const deleteRoleFailure = () => ({
  type: DELETE_ROLES_FAILURE,
});
export const activeInactiveRole = (payload) => ({
  type: ACTIVE_INACTIVE_ROLE,
  payload,
});
export const activeInactiveRoleSuccess = () => ({
  type: ACTIVE_INACTIVE_ROLE_SUCCESS,
});
export const activeInactiveRoleFailure = () => ({
  type: ACTIVE_INACTIVE_ROLE_FAILURE,
});
export const getPermission = () => ({
  type: GET_PERMISSION,
});
export const getPermissionSuccess = (payload) => ({
  type: GET_PERMISSION_SUCCESS,
  payload,
});
export const getPermissionFailure = () => ({
  type: GET_PERMISSION_FAILURE,
});
export const getEmployerPermission = () => ({
  type: GET_EMPLOYER_PERMISSION,
});
export const getEmployerPermissionSuccess = (payload) => ({
  type: GET_EMPLOYER_PERMISSION_SUCCESS,
  payload,
});
export const getEmployerPermissionFailure = () => ({
  type: GET_EMPLOYER_PERMISSION_FAILURE,
});
export const addRoles = (payload) => ({
  type: ADD_ROLES,
  payload,
});
export const addRolesSuccess = (payload) => ({
  type: ADD_ROLES_SUCCESS,
  payload,
});
export const addRolesFailure = (payload) => ({
  type: ADD_ROLES_FAILURE,
  payload,
});
export const getRolesResources = (payload) => ({
  type: GET_ROLES_RESOURCES,
  payload,
});
export const getRolesResourcesSuccess = (payload) => ({
  type: GET_ROLES_RESOURCES_SUCCESS,
  payload,
});
export const getRolesResourcesFailure = () => ({
  type: GET_ROLES_RESOURCE_FAILURE,
});
export const getResourceActions = (payload) => ({
  type: GET_RESOURCE_ACTIONS,
  payload,
});
export const getResourceActionsSuccess = (payload) => ({
  type: GET_RESOURCE_ACTIONS_SUCCESS,
  payload,
});
export const getResourceActionsFailure = () => ({
  type: GET_RESOURCE_ACTIONS_FAILURE,
});
export const getAllSubusers = (payload) => ({
  type: GET_ALL_SUB_USER,
  payload,
});
export const getAllSubusersSuccess = (payload) => ({
  type: GET_ALL_SUB_USER_SUCCESS,
  payload,
});
export const getAllSubusersFailure = () => ({
  type: GET_ALL_SUB_USER_FAILURE,
});

// USERS
export const getContactInquiry = (payload) => ({
  type: GET_CONTACT_INQUIRY,
  payload,
});
export const getContactInquirySuccess = (payload) => ({
  type: GET_CONTACT_INQUIRY_SUCCESS,
  payload,
});
export const getContactInquiryFailure = () => ({
  type: GET_CONTACT_INQUIRY_FAILURE,
});

export const getAnnouncementList = (payload) => ({
  type: GET_ANNOUNCEMENT_LIST,
  payload,
});
export const getAnnouncementListSuccess = (payload) => ({
  type: GET_ANNOUNCEMENT_LIST_SUCCESS,
  payload,
});
export const getAnnouncementListFailure = () => ({
  type: GET_ANNOUNCEMENT_LIST_FAILURE,
});
export const sendAnnouncementItem = (payload) => ({
  type: SEND_ANNOUNCEMENT_ITEM,
  payload,
});
export const sendAnnouncementItemSuccess = (payload) => ({
  type: SEND_ANNOUNCEMENT_ITEM_SUCCESS,
  payload,
});
export const sendAnnouncementItemFailure = () => ({
  type: SEND_ANNOUNCEMENT_ITEM_FAILURE,
});
export const deleteAnnouncement = (payload) => ({
  type: DELETE_ANNOUNCEMENT,
  payload,
});
export const deleteAnnouncementSuccess = () => ({
  type: DELETE_ANNOUNCEMENT_SUCCESS,
});
export const deleteAnnouncementFailure = () => ({
  type: DELETE_ANNOUNCEMENT_FAILURE,
});
export const getCompanyDetails = (payload) => ({
  type: GET_COMPANY_DETAILS,
  payload,
});
export const getCompanyDetailsSuccess = (payload) => ({
  type: GET_COMPANY_DETAILS_SUCCESS,
  payload,
});
export const getCompanyDetailsFailure = () => ({
  type: GET_COMPANY_DETAILS_FAILURE,
});
export const updateCompanyDetails = (payload) => ({
  type: UPDATE_COMPANY_DETAILS,
  payload,
});
export const updateCompanyDetailsSuccess = () => ({
  type: UPDATE_COMPANY_DETAILS_SUCCESS,
});
export const updateCompanyDetailsFailure = () => ({
  type: UPDATE_COMPANY_DETAILS_FAILURE,
});

export const changeEmployerStatus = (payload) => ({
  type: CHANGE_EMPLOYER_STATUS,
  payload,
});
export const changeEmployerStatusSuccess = () => ({
  type: CHANGE_EMPLOYER_STATUS_SUCCESS,
});
export const changeEmployerStatusFailure = () => ({
  type: CHANGE_EMPLOYER_STATUS_FAILURE,
});

export const getUserInformation = (payload) => ({
  type: GET_USER_INFORMATION,
  payload,
});
export const getUserInformationSuccess = (payload) => ({
  type: GET_USER_INFORMATION_SUCCESS,
  payload,
});
export const getUserInformationFailure = () => ({
  type: GET_USER_INFORMATION_FAILURE,
});
export const updateUserInformation = (payload) => ({
  type: UPDATE_USER_INFORMATION,
  payload,
});
export const updateUserInformationSuccess = () => ({
  type: UPDATE_USER_INFORMATION_SUCCESS,
});
export const updateUserInformationFailure = () => ({
  type: UPDATE_USER_INFORMATION_FAILURE,
});
export const getSupportTicket = (payload) => ({
  type: GET_SUPPORT_TICKET,
  payload,
});
export const getSupportTicketSuccess = (payload) => ({
  type: GET_SUPPORT_TICKET_SUCCESS,
  payload,
});
export const getSupportTicketFailure = () => ({
  type: GET_SUPPORT_TICKET_FAILURE,
});
export const getFaqList = (payload) => ({
  type: GET_FAQ_LIST,
  payload,
});
export const getFaqListSuccess = (payload) => ({
  type: GET_FAQ_LIST_SUCCESS,
  payload,
});
export const getFaqListFailure = () => ({
  type: GET_FAQ_LIST_FAILURE,
});
export const getTagList = () => ({
  type: GET_TAG_LIST,
});
export const getTagListSuccess = (payload) => ({
  type: GET_TAG_LIST_SUCCESS,
  payload,
});
export const getTagListFailure = () => ({
  type: GET_TAG_LIST_FAILURE,
});
export const updateSupportTicket = (payload) => ({
  type: UPDATE_SUPPORT_TICKET_STATUS,
  payload,
});
export const updateSupportTicketSuccess = () => ({
  type: UPDATE_SUPPORT_TICKET_STATUS_SUCCESS,
});
export const updateSupportTicketFailure = () => ({
  type: UPDATE_SUPPORT_TICKET_STATUS_FAILURE,
});

export const sendSubuserVerificationMail = (payload) => ({
  type: SEND_SUBUSER_VERIFICATION_MAIL,
  payload,
});
export const sendSubuserVerificationMailSuccess = () => ({
  type: SEND_SUBUSER_VERIFICATION_MAIL_SUCCESS,
});
export const sendSubuserVerificationMailFailure = () => ({
  type: SEND_SUBUSER_VERIFICATION_MAIL_FAILURE,
});
export const addOrEditFaq = (payload) => ({
  type: ADD_OR_EDIT_FAQ,
  payload,
});
export const addOrEditFaqSuccess = () => ({
  type: ADD_OR_EDIT_FAQ_SUCCESS,
});
export const addOrEditFaqFailure = () => ({
  type: ADD_OR_EDIT_FAQ_FAILURE,
});
export const deleteFaqList = (payload) => ({
  type: DELETE_FAQ_LIST,
  payload,
});
export const deleteFaqListSuccess = () => ({
  type: DELETE_FAQ_LIST_SUCCESS,
});
export const deleteFaqListFailure = () => ({
  type: DELETE_FAQ_LIST_FAILURE,
});
export const deleteFaqsList = (payload) => ({
  type: DELETE_FAQS_LIST,
  payload,
});
export const deleteFaqsListSuccess = () => ({
  type: DELETE_FAQS_LIST_SUCCESS,
});
export const deleteFaqsListFailure = () => ({
  type: DELETE_FAQS_LIST_FAILURE,
});
export const changePassword = (payload) => ({
  type: CHANGE_PASSWORD,
  payload,
});
export const changePasswordSuccess = (payload) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});
export const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAILURE,
});

//PRODUCT PERMISSION
export const getProductPermission = (payload) => ({
  type: GET_PRODUCT_PERMISSION,
  payload,
});
export const getProductPermissionSuccess = (payload) => ({
  type: GET_PRODUCT_PERMISSION_SUCCESS,
  payload,
});
export const getProductPermissionFailure = () => ({
  type: GET_PRODUCT_PERMISSION_FAILURE,
});

export const getProductPermissionCredit = (payload) => ({
  type: GET_PRODUCT_PERMISSION_CREDIT,
  payload,
});
export const getProductPermissionCreditSuccess = (payload) => ({
  type: GET_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  payload,
});
export const getProductPermissionCreditFailure = () => ({
  type: GET_PRODUCT_PERMISSION_CREDIT_FAILURE,
});

export const getProductPermissionCreditById = (payload) => ({
  type: GET_PRODUCT_PERMISSION_CREDIT_BY_ID,
  payload,
});
export const getProductPermissionCreditByIdSuccess = (payload) => ({
  type: GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS,
  payload,
});
export const getProductPermissionCreditByIdFailure = () => ({
  type: GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE,
});

export const updateProductPermissionCredit = (payload) => ({
  type: UPDATE_PRODUCT_PERMISSION_CREDIT,
  payload,
});
export const updateProductPermissionCreditSuccess = (payload) => ({
  type: UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  payload,
});
export const updateProductPermissionCreditFailure = () => ({
  type: UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE,
});

export const updateProductPermission = (payload) => ({
  type: UPDATE_PRODUCT_PERMISSION,
  payload,
});
export const updateProductPermissionSuccess = (payload) => ({
  type: UPDATE_PRODUCT_PERMISSION_SUCCESS,
  payload,
});
export const updateProductPermissionFailure = () => ({
  type: UPDATE_PRODUCT_PERMISSION_FAILURE,
});
export const addProductPermission = (payload) => ({
  type: ADD_PRODUCT_PERMISSION,
  payload,
});
export const addProductPermissionSuccess = () => ({
  type: ADD_PRODUCT_PERMISSION_SUCCESS,
});
export const addProductPermissionFailure = () => ({
  type: ADD_PRODUCT_PERMISSION_FAILURE,
});
export const notifyClient = (payload) => ({
  type: NOTIFY_CLIENT,
  payload,
});

export const notifyClientSuccess = () => ({
  type: NOTIFY_CLIENT_SUCCESS,
});

export const notifyClientFailure = () => ({
  type: NOTIFY_CLIENT_FAILURE,
});

export const notifyIncserveClient = (payload) => ({
  type: NOTIFY_INCSERVE_CLIENT,
  payload,
});

export const notifyIncserveClientSuccess = () => ({
  type: NOTIFY_INCSERVE_CLIENT_SUCCESS,
});

export const notifyIncserveClientFailure = () => ({
  type: NOTIFY_INCSERVE_CLIENT_FAILURE,
});

export const getEmployerSubuserDetails = (payload) => ({
  type: GET_EMPLOYER_SUBUSER_DETAILS,
  payload,
});

export const getEmployerSubuserDetailsSuccess = (payload) => ({
  type: GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS,
  payload,
});

export const getEmployerSubuserDetailsFailure = (payload) => ({
  type: GET_EMPLOYER_SUBUSER_DETAILS_FAILURE,
});

export const getAllNotifications = (payload) => ({
  type: GET_ALL_NOTIFICATIONS,
  payload,
});

export const getAllNotificationsSuccess = (payload) => ({
  type: GET_ALL_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getAllNotificationsFailure = () => ({
  type: GET_ALL_NOTIFICATIONS_FAILURE,
});

export const readAllNotifications = () => ({
  type: READ_ALL_NOTIFICATIONS,
});

export const readAllNotificationsSuccess = () => ({
  type: READ_ALL_NOTIFICATIONS_SUCCESS,
});

export const readAllNotificationsFailure = () => ({
  type: READ_ALL_NOTIFICATIONS_FAILURE,
});

export const deleteNotification = (payload) => ({
  type: DELETE_NOTIFICATION,
  payload,
});

export const deleteNotificationSuccess = () => ({
  type: DELETE_NOTIFICATION_SUCCESS,
});

export const deleteNotificationFailure = () => ({
  type: DELETE_NOTIFICATION_FAILURE,
});

export const subAdminPasswordReset = (payload) => ({
  type: SUB_ADMIN_PASSWORD_RESET,
  payload,
});
export const subAdminPasswordResetSuccess = () => ({
  type: SUB_ADMIN_PASSWORD_RESET_SUCCESS,
});
export const subAdminPasswordResetFailure = () => ({
  type: SUB_ADMIN_PASSWORD_RESET_FAILURE,
});

export const generateTwoFactorAuthenticationUri = (payload) => ({
  type: GENERATE_TWO_FACTOR_AUTHENTICATION_URI,
  payload,
});
export const generateTwoFactorAuthenticationUriSuccess = (payload) => ({
  type: GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  payload,
});
export const generateTwoFactorAuthenticationUriFailure = () => ({
  type: GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
});

export const verifyTwoFactorAuthenticationUri = (payload) => ({
  type: VERIFY_TWO_FACTOR_AUTHENTICATION_URI,
  payload,
});
export const verifyTwoFactorAuthenticationUriSuccess = (payload) => ({
  type: VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  payload,
});
export const verifyTwoFactorAuthenticationUriFailure = () => ({
  type: VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
});

export const disableTwoFactorAuthenticationUri = (payload) => ({
  type: DISABLE_TWO_FACTOR_AUTHENTICATION_URI,
  payload,
});
export const disableTwoFactorAuthenticationUriSuccess = (payload) => ({
  type: DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  payload,
});
export const disableTwoFactorAuthenticationUriFailure = () => ({
  type: DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
});

export const twoFactorAuthenticationStatus = (payload) => ({
  type: TWO_FACTOR_AUTHENTICATION_STATUS,
  payload,
});
export const twoFactorAuthenticationStatusSuccess = (payload) => ({
  type: TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  payload,
});
export const twoFactorAuthenticationStatusFailure = () => ({
  type: TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
});

export const checkTwoFactorAuthenticationStatus = (payload) => ({
  type: CHECK_TWO_FACTOR_AUTHENTICATION_STATUS,
  payload,
});
export const checkTwoFactorAuthenticationStatusSuccess = (payload) => ({
  type: CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  payload,
});
export const checkTwoFactorAuthenticationStatusFailure = () => ({
  type: CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
});

export const getMockInterviews = (payload) => ({
  type: GET_MOCK_INTERVIEWS,
  payload,
});
export const getMockInterviewsSuccess = (payload) => ({
  type: GET_MOCK_INTERVIEWS_SUCCESS,
  payload,
});

export const getMockInterviewsFailure = () => ({
  type: GET_MOCK_INTERVIEWS_FAILURE,
});
